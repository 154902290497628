import React from 'react'
import { Link } from 'gatsby'

const Hero = ({ title, subtitle }) => {
  // const data = useStaticQuery(query)
  // const hero = data.strapiHomepage.hero

  console.log("Hero.render hero", title);

  return (
    <section className="hero has-background-primary-dark	">
      <div className="hero-body">
        <Link to="/">
          <div className="container has-text-centered ">
            <h1 className="title has-text-white-ter">
              { title }
            </h1>
            {subtitle &&
              <p className="subtitle has-text-white-bis">
                { subtitle }
              </p>
            }
          </div>
        </Link>
      </div>
    </section>
  )
}

export default Hero
